import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/common/google_map.png'
import _imports_1 from '@/assets/common/apple_map.png'


const _hoisted_1 = { class: "home mb-auto bg-neutral-900 text-white/85" }

import NavBar from '@/template/NavBar.vue';
import Footer from '@/template/Footer.vue';
import CopyableWidget from '@/components/common/CopyableWidget.vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'Address',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(NavBar),
    _cache[0] || (_cache[0] = _createStaticVNode("<div class=\"container mx-auto mt-8 mb-16\"><div class=\"mx-4 md:mx-16 flex flex-col justify-center items-center\"><h1 class=\"mt-8 text-2xl text-center font-bold text-green-500/85\">Welcome<br>to Isaac &amp; Emily&#39;s home!</h1><h2 class=\"font-medium text-lg text-white/85 mt-4\">10405 SW 55th Ter, Mustang, OK 73064</h2><div class=\"flex flex-col space-y-4 items-center justify-center mt-8 text-white/75\"><div class=\"text-lg text-white/50\">Navigate with</div><div class=\"flex flex-row space-x-8\"><a href=\"https://maps.app.goo.gl/rwxJ1VR2U9czqadV9?g_st=com.google.maps.preview.copy\"><div class=\"flex flex-col space-y-2\"><div class=\"flex flex-col bg-white w-16 h-16 items-center justify-center rounded-2xl\"><img class=\"w-12\" src=\"" + _imports_0 + "\" alt=\"\"></div><p class=\"text-xs\">Google Map</p></div></a><a href=\"https://maps.apple.com/?address=10405%20SW%2055th%20Terr,%20Mustang,%20OK%20%2073064,%20United%20States&amp;ll=35.409943,-97.702939&amp;q=10405%20SW%2055th%20Terr&amp;t=m\"><div class=\"flex flex-col space-y-2\"><img class=\"w-16\" src=\"" + _imports_1 + "\" alt=\"\"><p class=\"text-xs\">Apple Map</p></div></a></div></div></div></div>", 1)),
    _createVNode(Footer)
  ]))
}
}

})