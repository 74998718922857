<template>
<!--  <div class="text-md md:text-lg mb-6 text-indigo-500 text-left" v-if="!isBooked">Make sure your appointment look right.<br/>Please let us know if you have any additional note.</div>-->

  <div class="flex flex-col" v-if="isBooked">
<!--      <i class="fa-solid fa-circle-check text-green-500 text-2xl"></i>-->
      <div class="flex flex-col text-left items-start space-y-4">
        <div class="flex flex-row items-center space-x-2">
          <i class="fa-solid fa-circle-check text-green-500 text-2xl"></i>
          <span class="text-green-500 text-lg"> Sent</span>
        </div>

        <div class="mt-0">Thank you for choosing our services :-)</div>
        <div class="mt-0">Your appointment request has been received, and we will confirm it shortly.</div>
        <div class="mt-0">Please give us a call if you're going to be late or need to reschedule.</div>
      </div>
    <hr class="border-gray-300 my-6"/>

  </div>

  <div class="md:flex md:flex-row md:items-stretch md:justify-between">
    <div class="mb-6 flex flex-col items-start">
      <div class="">Your info</div>
      <div class="flex items-center">
        <div
            class="font-bold text-indigo-500"
            v-if="editingCustomer.firstName !== '' || editingCustomer.lastName !== ''"
        >
          {{ editingCustomer.firstName }} {{ editingCustomer.lastName }}
        </div>
        <div class="text-red-500 text-sm" v-else>Please enter your firstname/lastname</div>
        <div class="text-indigo-500 ml-4 font-bold">
          {{ editingCustomer.phone }}
        </div>
      </div>
    </div>

    <div class="mb-6 flex flex-col items-start">
      <div class="">Technicians</div>
      <div class="flex flex-row flex-wrap" v-if="selectingStaffs.length !== 0">
        <div v-for="staff in selectingStaffs" :key="staff.id" class="font-bold text-indigo-500">
          {{ staff.first_name }}
        </div>
      </div>
      <div class="text-indigo-500" v-else-if="isAnyAvailableTech">
        Any available technician
      </div>
      <div class="text-red-500 text-sm" v-else>Please choose a technician</div>
    </div>

    <div class="mb-6 flex flex-col items-start">
      <div class="">Services</div>
      <div class="flex flex-row flex-wrap" v-if="selectingServices.length !== 0">
        <div v-for="service in selectingServices" :key="service.id" class="font-bold text-indigo-500 mr-6">
          {{ service.short_name }}
        </div>
      </div>
      <div class="text-red-500 text-sm" v-else>Please choose at least one service</div>
    </div>

    <div class="mb-6 flex flex-col items-start">
      <!--      <div class="flex flex-row">Date & Time  &nbsp; <DateInfo :selectingDate="selectingDate"/></div>-->
      <div class="">Date & Time</div>
      <div class="flex flex-col items-start">
        <div class="font-bold text-indigo-500 mr-3">
          {{ formatDate(selectingDate) }}
        </div>
        <div class="flex flex-row">
          <div class="">at</div>
          <div class="font-bold text-indigo-500 mx-3" v-if="selectingTime !== ''">
            {{ selectingTime }}
          </div>
          <div class="text-red-500 mx-3 text-sm" v-else>Please choose a time</div>
        </div>
      </div>
    </div>
  </div>

  <div class="mb-6 flex flex-col items-start md:w-96" v-if="!isBooked">
    <label class="mb-2" for="note"> Any note for us? </label>
    <textarea
        v-model="note"
        @input="handleInputChange"
        type="text"
        id="note"
        name="note"
        class="w-full p-2 border border-gray-400 rounded-xl"
        required
    />
  </div>

  <div class="mb-6 flex flex-col items-start md:w-96" v-else>
    <div class="mb-0">Note</div>
    <div class="text-indigo-500 font-bold" v-if="editingNote !== ''">
      {{ editingNote }}
    </div>
    <div class="text-indigo-500" v-else>Empty</div>
  </div>

  <!--  <div class="" >-->
  <!--  <div class="font-bold text-left text-indigo-500 mb-4" v-if="isBooked">-->
  <!--    <i class="fa-solid fa-circle-exclamation"></i> Please give us a call if you're going to be late or need to reschedule.-->
  <!--  </div>-->

  <ReminderOptions
      :preferredReminderMethod="preferredReminderMethod"
      :isConsented="isConsented"
      :wantsToReceiveConfirmationRightAfter="wantsToReceiveConfirmationRightAfter"
      :is24HoursReminded="is24HoursReminded"
      :store="store"
      :isBooked="isBooked"
      @updateReminderOptions="updateReminderOptions"
  />

  <hr class="border-gray-300 my-6" v-if="isBooked"/>
  <div
      class="flex flex-col md:flex-row items-start md:items-center md:justify-between"
      v-if="isBooked"
  >
    <div class="text-indigo-500 font-bold mb-4">{{ store.name }}</div>

    <div class="flex flex-row items-center">
      <i class="fa-solid fa-phone text-gray-500 mr-3"></i>
      <a v-bind:href="'tel:' + store.phone" class="underline">{{ store.phone }}</a>
    </div>

    <div class="flex flex-row items-center mt-4 md:mt-0">
      <i class="fa-solid fa-location-dot text-gray-500 mr-3"></i>
      <p class="text-sm">{{ store.address }}</p>
    </div>

    <!--    <div class="flex flex-row">-->
    <!--      <a href="https://maps.app.goo.gl/1XRet8YMW78zfssJA?g_st=ic">-->
    <!--        <div-->
    <!--            class="flex items-center border pl-0 px-0 pr-4 rounded-xl border-gray-300 ml-6 mt-4 md:mt-0 mr-4"-->
    <!--        >-->
    <!--          <div-->
    <!--              class="flex bg-white w-10 h-10 mr-2 ml-1 items-center justify-center rounded-xl"-->
    <!--          >-->
    <!--            <img class="w-6" src="../../../../../assets/google_map.png" alt=""/>-->
    <!--          </div>-->
    <!--          <p class="text-sm">Google Map</p>-->
    <!--        </div>-->
    <!--      </a>-->

    <!--      <a-->
    <!--          href="https://maps.apple.com/?address=1719%20S%20Morgan%20Rd,%20Oklahoma%20City,%20OK%20%2073128,%20United%20States&auid=11128051628244022856&ll=35.448151,-97.690181&lsp=9902&q=Oasis%20Nails"-->
    <!--      >-->
    <!--        <div-->
    <!--            class="flex items-center border pl-0 px-0 pr-4 rounded-xl border-gray-300 mt-4 md:mt-0"-->
    <!--        >-->
    <!--          <img class="w-10 mr-4" src="../../../../../assets/apple_map.png" alt=""/>-->
    <!--          <p class="text-sm">Apple Map</p>-->
    <!--        </div>-->
    <!--      </a>-->
    <!--    </div>-->
  </div>
</template>

<script>
import StaffButton from '@/views/projects/rocket-tickets/appointment/book-appointment/staffs-tab/StaffButton.vue';
import ReminderOptions from '@/views/projects/rocket-tickets/appointment/book-appointment/summary-tab/ReminderOptions.vue';
import DateInfo from '@/views/projects/rocket-tickets/appointment/book-appointment/datetime-tab/DateInfo.vue';

export default {
  name: 'AppointmentSummaryTab',
  components: {
    DateInfo,
    ReminderOptions,
    StaffButton,
  },
  props: {
    isBooked: {
      type: Boolean,
      required: true,
    },
    editingNote: {
      type: String,
      required: true,
    },
    selectingStaffs: Array,
    selectingServices: Array,

    selectingDate: String,
    selectingTime: String,
    editingCustomer: {
      type: Object,
      required: true,
    },
    isAnyAvailableTech: Boolean,
    preferredReminderMethod: String,
    isConsented: Boolean,
    wantsToReceiveConfirmationRightAfter: Boolean,
    is24HoursReminded: Boolean,

    store: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      note: this.editingNote,
    };
  },
  mounted() {
    console.log('AppointmentSummaryTab: mounted');
    console.log(this.selectingDate);
    // console.log(this.store);
  },
  methods: {
    formatOrdinalSuffix(day) {
      if (day >= 11 && day <= 13) {
        return day + 'th';
      }
      switch (day % 10) {
        case 1:
          return day + 'st';
        case 2:
          return day + 'nd';
        case 3:
          return day + 'rd';
        default:
          return day + 'th';
      }
    },

    formatDate(inputDateString) {
      const inputDate = new Date(inputDateString);

      const options = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      };

      // const formattedDate = new Intl.DateTimeFormat('en-US', options).format(inputDate);
      // const day = inputDate.getDate();
      // const ordinalSuffix = this.formatOrdinalSuffix(day);

      inputDateString = inputDateString + 'T00:00:00';

      const hyphenatedDate = inputDateString.replace(/-/g, '\/')
          .replace(/T.+/, '');

      const date = new Date(hyphenatedDate);
      //
      const formattedDate = date.toLocaleDateString('en-US', {
        weekday: 'long',
        month: 'long',
        day: 'numeric',
        year: 'numeric',
        // timeZone: "America/Chicago",
      });

      // return formattedDate.replace(String(day), ordinalSuffix);
      return formattedDate;
    },

    handleInputChange() {
      console.log(this.note);
      this.$emit('updateNote', this.note);
    },

    updateReminderOptions(isConsented, wantsToReceiveConfirmationRightAfter, is24HoursReminded) {
      this.$emit('updateReminderOptions', isConsented, wantsToReceiveConfirmationRightAfter, is24HoursReminded);
    },
  },
};
</script>
