<template>
  <button class="md:w-32 rounded-xl md:hover:bg-gray-100 md:py-2 text-[12px] md:text-md" type="button" @click="switchCurrentTab(name)" :class="{ 'text-indigo-500': currentTab === name, 'font-bold': currentTab === name }">
    <div v-if="name==='Services' && selectingServices.length > 0"><i class="fa-solid fa-circle-check text-green-500"></i></div>
    <div v-else-if="name==='Technicians' && (selectingStaffs.length > 0 || isAnyAvailableTech)"><i class="fa-solid fa-circle-check text-green-500"></i></div>
    <div v-else-if="name==='Date Time' && selectingDate !== '' && selectingTime !== ''"><i class="fa-solid fa-circle-check text-green-500"></i></div>
    <div v-else-if="name==='Your Info' && editingCustomer.firstName !== '' && editingCustomer.lastName !== '' && editingCustomer.phone !== ''"><i class="fa-solid fa-circle-check text-green-500"></i></div>
<!--    <div v-else-if="name==='Review' && selectingServices.length > 0 && selectingStaffs.length > 0 && selectingDate !== '' && selectingTime !== '' && editingCustomer.firstName !== '' && editingCustomer.lastName !== '' && editingCustomer.phone !== ''"><i class="fa-solid fa-circle-check text-green-500"></i></div>-->
    <div v-else>{{ index }}</div>
    {{ name }}
  </button>
</template>

<script>
export default {
  name: 'TabButton',
  components: {
  },
  props: {
    name: String,
    index: String,
    currentTab: String,

    selectingStaffs: Array,
    isAnyAvailableTech: Boolean,

    selectingServices: Array,

    selectingDate: String,
    selectingTime: String,

    editingCustomer: Object,
  },
  methods: {
    switchCurrentTab(newTab) {
      console.log(`Switch to ${newTab} tab`);
      this.$emit('switchTab', newTab);
    },
  },
};
</script>
