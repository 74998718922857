<template>
  <div class="sticky top-0 z-50 bg-white drop-shadow py-6 mb-6">
    <div class="container mx-auto">
      <div class="flex flex-row items-start justify-between md:justify-center mx-4 mb-4 pr-1" v-if="!isBooked">
        <TabButton :currentTab="currentTab" index="1" name="Services"
                   :key="1"
                   @switchTab="switchCurrentTab"
                   :selectingStaffs="selectingStaffs"
                   :isAnyAvailableTech="isAnyAvailableTech"
                   :selectingServices="selectingServices"
                   :selectingDate="selectingDate"
                   :selectingTime="selectingTime"
                   :editingCustomer="editingCustomer"/>
        <TabButton :currentTab="currentTab" index="2" name="Technicians"
                    :key="2"
                   @switchTab="switchCurrentTab"
                   :selectingStaffs="selectingStaffs"
                   :isAnyAvailableTech="isAnyAvailableTech"
                   :selectingServices="selectingServices"
                   :selectingDate="selectingDate"
                   :selectingTime="selectingTime"
                   :editingCustomer="editingCustomer"/>
        <TabButton :currentTab="currentTab" index="3" name="Date Time"
                    :key="3"
                   @switchTab="switchCurrentTab"
                   :selectingStaffs="selectingStaffs"
                   :isAnyAvailableTech="isAnyAvailableTech"
                   :selectingServices="selectingServices"
                   :selectingDate="selectingDate"
                   :selectingTime="selectingTime"
                   :editingCustomer="editingCustomer"/>
        <TabButton :currentTab="currentTab" index="4" name="Your Info"
                    :key="4"
                   @switchTab="switchCurrentTab"
                   :selectingStaffs="selectingStaffs"
                   :isAnyAvailableTech="isAnyAvailableTech"
                   :selectingServices="selectingServices"
                   :selectingDate="selectingDate"
                   :selectingTime="selectingTime"
                   :editingCustomer="editingCustomer"/>
        <TabButton :currentTab="currentTab" index="5" name="Review"
                    :key="5"
                   @switchTab="switchCurrentTab"
                   :selectingStaffs="selectingStaffs"
                   :isAnyAvailableTech="isAnyAvailableTech"
                   :selectingServices="selectingServices"
                   :selectingDate="selectingDate"
                   :selectingTime="selectingTime"
                   :editingCustomer="editingCustomer"/>
      </div>
      <div class="flex items-center justify-between mx-4 md:mx-16" v-if="!isBooked">
        <button class="bg-gray-300 text-white rounded-xl px-4 md:px-8 py-0 md:py-1 text-xl" v-if="currentTab === 'Services'">
          <i class="fa-solid fa-angle-left"></i>
        </button>
        <button class="md:h-9 md:hover:bg-indigo-600 bg-indigo-500 text-white rounded-xl px-4 md:px-8 py-0 md:py-1 text-xl" v-else-if="currentTab === 'Technicians'" type="button" @click="switchCurrentTab('Services')">
          <i class="fa-solid fa-angle-left"></i>
        </button>
        <button class="md:h-9 md:hover:bg-indigo-600 bg-indigo-500 text-white rounded-xl px-4 md:px-8 py-0 md:py-1 text-xl" v-else-if="currentTab === 'Date Time'" type="button" @click="switchCurrentTab('Technicians')">
          <i class="fa-solid fa-angle-left"></i>
        </button>
        <button class="md:h-9 md:hover:bg-indigo-600 bg-indigo-500 text-white rounded-xl px-4 md:px-8 py-0 md:py-1 text-xl" v-else-if="currentTab === 'Your Info'" type="button" @click="switchCurrentTab('Date Time')">
          <i class="fa-solid fa-angle-left"></i>
        </button>
        <button class="md:h-9 md:hover:bg-indigo-600 bg-indigo-500 text-white rounded-xl px-4 md:px-8 py-0 md:py-1 text-xl" v-else-if="currentTab === 'Review'" type="button" @click="switchCurrentTab('Your Info')">
          <i class="fa-solid fa-angle-left"></i>
        </button>
        <button class="md:h-9 md:hover:bg-indigo-600 bg-indigo-500 text-white rounded-xl px-4 md:px-8 py-0 md:py-1 text-xl" v-else type="button" @click="">
          Error
        </button>

        <div class="font-bold text-xl md:text-3xl text-indigo-500 ">{{ currentTab }}</div>

        <button class="md:h-9 md:hover:bg-indigo-600 bg-indigo-500 text-white rounded-xl px-4 md:px-8 py-0 md:py-1 text-xl" v-if="currentTab === 'Services'" type="button" @click="switchCurrentTab('Technicians')">
          <i class="fa-solid fa-angle-right"></i>
        </button>
        <button class="md:h-9 md:hover:bg-indigo-600 bg-indigo-500 text-white rounded-xl px-4 md:px-8 py-0 md:py-1 text-xl" v-else-if="currentTab === 'Technicians'" type="button" @click="switchCurrentTab('Date Time')">
          <i class="fa-solid fa-angle-right"></i>
        </button>
        <button class="md:h-9 md:hover:bg-indigo-600 bg-indigo-500 text-white rounded-xl px-4 md:px-8 py-0 md:py-1 text-xl" v-else-if="currentTab === 'Date Time'" type="button" @click="switchCurrentTab('Your Info')">
          <i class="fa-solid fa-angle-right"></i>
        </button>
        <button class="md:h-9 md:hover:bg-indigo-600 bg-indigo-500 text-white rounded-xl px-4 md:px-8 py-0 md:py-1 text-xl" v-else-if="currentTab === 'Your Info'" type="button" @click="switchCurrentTab('Review')">
          <i class="fa-solid fa-angle-right"></i>
        </button>
        <button class="md:h-12 md:hover:bg-indigo-600 bg-indigo-500 text-white rounded-xl px-4 md:px-8 py-3 text-sm font-bold" v-else-if="currentTab === 'Review' && !isBooked && !isBooking" type="button" @click="bookAppointment()">
          <div class="flex flex-row items-center justify-center">
            <i class="fa-regular fa-paper-plane mr-2"></i>
            Book
          </div>
        </button>
        <div class="text-gray-500 rounded-xl px-2 py-3 text-lg" v-else-if="currentTab === 'Review' && isBooking">
          <i class="fa-solid fa-spinner"></i> Sending
        </div>
        <button class="text-indigo-500 rounded-xl px-8 py-0 text-sm" v-else type="button" @click="">
          Error
        </button>
      </div>

      <!--        <hr class="border-gray-300 mt-10" v-if="!isBooked"/>-->


      <div class="flex flex-col mx-4 md:mx-16" v-if="isBooked">
        <!--          <div class="flex items-center bg-green-0 rounded-xl">-->
        <!--            <i class="fa-solid fa-circle-check text-green-500 text-2xl"></i>-->
        <!--            <div class="flex flex-col text-left items-start pl-4">-->
        <!--              <div>Thank you for choosing our services!</div>-->
        <!--              <div>Your appointment request has been received, and we will confirm it shortly.</div>-->
        <!--              <div>Please give us a call if you're going to be late or need to reschedule.</div>-->
        <!--            </div>-->
        <!--          </div>-->
        <div class="flex justify-between">
          <!--            <button class="bg-indigo-500 text-white rounded-xl p-4 mt-4 text-sm" type="button" @click="addEventToCalendar()">-->
          <!--                <div class="flex flex-row items-center justify-center">-->
          <!--                  <i class="fa-regular fa-calendar-plus mr-2 text-"></i>-->
          <!--                        Add to calendar-->
          <!--                </div>-->
          <!--            </button>-->
          <div></div>
          <button class="bg-indigo-500 md:hover:bg-indigo-600 text-white rounded-xl p-4 mt-0 text-sm" type="button" @click="startOver()">
            Book another appointment
          </button>
        </div>
      </div>

<!--      <div v-if="$route.path.includes('booking-in-store') && isBooked" class="">-->
<!--        <CountDownBar :max-value="100" :current-value="counter"></CountDownBar>-->
<!--        <button @click="incrementCounter">Increment</button>-->
<!--      </div>-->

    </div>

  </div>


</template>

<script>

import TabButton from '@/views/projects/rocket-tickets/appointment/book-appointment/TabButton.vue';
import CountDownBar from '@/views/projects/rocket-tickets/appointment/book-appointment/CountDownBar.vue';
import { ref } from 'vue';

export default {
  name: 'ApptBookStatusBar',
  components: {
    CountDownBar,
    TabButton,
  },
  props: {
    currentTab: String,
    isBooking: Boolean,
    isBooked: Boolean,

    selectingStaffs: Array,
    isAnyAvailableTech: Boolean,

    selectingServices: Array,

    selectingDate: String,
    selectingTime: String,

    editingCustomer: Object,
  },



  methods: {
    switchCurrentTab(newTab) {
      this.$emit('switchTab', newTab);
    },
    bookAppointment() {
      console.log('Book appointment');
      this.$emit('bookAppointment');
    },
    startOver() {
      console.log('Start over');
      this.$emit('startOver');
    },
    addEventToCalendar() {
      console.log('Add event to calendar');
      this.$emit('addEventToCalendar');
    },
  },
};

</script>
