import { createRouter, createWebHistory, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import AboutView from '../views/about/AboutView.vue';
import Address from '../views/address/Address.vue';
import AppointmentBookingView from '../views/projects/rocket-tickets/appointment/book-appointment/BookAppointmentView.vue';
import RocketTicketView from '@/views/projects/rocket-tickets/RocketTicketView.vue';
import RocketTicketsPricing from '@/views/projects/rocket-tickets/RocketTicketsPricing.vue';
import RocketTicketsBilling from '@/views/projects/rocket-tickets/store/RocketTicketsBilling.vue';
import RocketTicketsDownload from '@/views/projects/rocket-tickets/RocketTicketsDownload.vue';
import RocketTicketsGuide from '@/views/projects/rocket-tickets/RocketTicketsGuide.vue';
import RocketTicketsAuth from '@/views/projects/rocket-tickets/RocketTicketsAuth.vue';
import RocketTicketsStoreProfile from '@/views/projects/rocket-tickets/store/RocketTicketsStoreProfile.vue';

import WebsiteDesignAdView from '@/views/projects/website-design/WebsiteDesignAdView.vue';
import OnlineAppointmentBookingAdView from '@/views/projects/online-booking/OnlineBookingAdView.vue';


const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView
  },
  {
    path: '/address',
    name: 'address',
    component: Address
  },
  {
    path: '/addr',
    redirect: { name: 'address' },
  },
  {
    path: '/rocket-tickets',
    name: 'rocket-tickets',
    component: RocketTicketView
  },
  // {path: '/rocket-tickets/overview',name: 'rocket-tickets',component: RocketTicketView,},
  {
    path: '/rocket-tickets/pricing',
    name: 'rocket-tickets-pricing',
    component: RocketTicketsPricing,
  },
  {
    path: '/rocket-tickets/download',
    name: 'rocket-tickets-download',
    component: RocketTicketsDownload,
  },
  {
    path: '/rocket-tickets/guide',
    name: 'rocket-tickets-guide',
    component: RocketTicketsGuide,
  },
  {
    path: '/rocket-tickets/auth',
    name: 'rocket-tickets-auth',
    component: RocketTicketsAuth,
  },
  {
    path: '/rocket-tickets/store',
    name: 'RocketTicketsStoreProfile',
    component: RocketTicketsStoreProfile,
  },
  {
    path: '/rocket-tickets/store/billing',
    name: 'RocketTicketsBilling',
    component: RocketTicketsBilling,
  },
  {
    path: '/rocket-tickets/store/:storeCuteID/appointment/booking',
    name: 'appointment-booking',
    component: AppointmentBookingView,
    props: true,
  },
  {
    path: '/rocket-tickets/store/:storeCuteID/appointment/booking-in-store',
    name: 'appointment-booking-at-store',
    component: AppointmentBookingView,
    props: true,
  },
  {
    path: '/website-design',
    name: 'website-design',
    component: WebsiteDesignAdView,
  },
  {
    path: '/online-appointment-booking',
    name: 'online-appointment-booking',
    component: OnlineAppointmentBookingAdView,
  },
  {
    path: '/blogs',
    name: 'blogs',
    component: () => import('../views/blogs/BlogsView.vue'),
  },
  {
    path: '/blogs/:article',
    name: 'article',
    component: () => import('../views/blogs/ArticleView.vue'),
  },
  {
    path: '/:catchAll(.*)',
    name: 'not-found',
    component: () => import('../views/NotFoundView.vue'),
  },
];

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  linkExactActiveClass: 'is-active',
  history: createWebHashHistory(),
  routes,
});

export default router;
