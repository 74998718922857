<template>
  <button class="h-12 w-[98px] bg-indigo-500 text-white font rounded-xl px-4 mb-4 mx-2 text-sm hover:border hover:border-indigo-800" v-if="selectingStaffs.some(x => x.id === staff.id)" type="button" @click="handleUnSelect"> {{ staff.first_name }}</button>
  <div class="" v-else>
    <button class="h-12 w-[98px] bg-gray-100 font rounded-xl px-4 mb-4 mx-2 text-sm text-neutral-400" v-if="!isStaffCapable()">
      <div class="flex flex-col"> {{ staff.first_name }}
        <div class="text-red-400 text-[10px]">Not available</div>
      </div>
    </button>
    <button class="h-12 w-[98px] bg-gray-100 text-black font rounded-xl px-4 mb-4 mx-2 text-sm hover:border hover:border-indigo-500" v-else type="button" @click="handleSelect"> {{ staff.first_name }}</button>
  </div>
</template>

<script>
export default {
  name: 'StaffButton',
  components: {},
  props: {
    staff: {
      type: Object,
      required: true,
    },
    selectingServices: {
      type: Object,
      required: true,
    },
    selectingStaffs: Array,
  },
  methods: {
    handleSelect() {
      // this.isSelected = true;
      console.log(`Select ${this.staff.first_name} staff`);
      this.$emit('addStaff', this.staff);
    },
    handleUnSelect() {
      console.log(`UnSelect ${this.staff.first_name} staff`);
      this.$emit('removeStaff', this.staff);
    },
    isStaffCapable() {
      let result = false;

      if (this.selectingServices.length === 0) {
        result = true;
      } else {
        result = this.selectingServices.every((x) => this.staff.capabilities.includes(x.id));
      }
    return result;

    // return true;
  }
  },
};
</script>
