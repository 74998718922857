<template>
  <div class="count-bar">
    <div class="bar" :style="{ width: barWidth }"></div>
    <div class="count">{{ count }}</div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';

export default {
  props: {
    maxValue: {
      type: Number,
      required: true,
    },
    currentValue: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    const count = ref(props.currentValue);

    const barWidth = computed(() => {
      const percentage = (count.value / props.maxValue) * 100;
      return `${percentage}%`;
    });

    return {
      count,
      barWidth,
    };
  },
  watch: {
    currentValue(newValue) {
      this.count.value = newValue;
    },
  },
};
</script>

<style scoped>
.count-bar {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
}

.bar {
  background-color: #42b983;
  height: 5px;
}

.count {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
  color: #fff;
}
</style>
