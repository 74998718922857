import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../assets/facebook.png'
import _imports_1 from '../assets/instagram.png'
import _imports_2 from '../assets/youtube.png'
import _imports_3 from '../assets/tiktok.png'
import _imports_4 from '../assets/bluesky.png'
import _imports_5 from '../assets/github.png'


const _hoisted_1 = { class: "bg-neutral-800 auto pt-0 md:pt-8 pb-8 md:pb-8" }
const _hoisted_2 = { class: "container mx-auto" }
const _hoisted_3 = { class: "flex flex-col-reverse justify-between mx-4 md:mx-16 space-y-8 md:flex-row md:space-y-0" }
const _hoisted_4 = { class: "flex justify-around text-white/85 md:space-x-32" }
const _hoisted_5 = { class: "flex flex-col space-y-3 items-start" }
const _hoisted_6 = { class: "flex flex-col space-y-3 items-start" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[8] || (_cache[8] = _createStaticVNode("<div class=\"mx-auto pt-8 text-white md:hidden\" data-v-0a2d754a>Copyright © 2022, All Rights Reserved</div><div class=\"flex flex-row items-center md:justify-start justify-between md:flex-col md:space-y-8 px-2 md:items-start\" data-v-0a2d754a><div class=\"text-green-500/85 text-2xl font-mono font-medium align-left\" data-v-0a2d754a>3AM LAB</div><div class=\"flex justify-center items-center space-x-2\" data-v-0a2d754a><a href=\"https://www.facebook.com/profile.php?id=61552993627014\" data-v-0a2d754a><img src=\"" + _imports_0 + "\" class=\"h-6 inverted brightness-50\" alt=\"\" data-v-0a2d754a></a><a href=\"https://www.instagram.com/3amlab.io/\" data-v-0a2d754a><img src=\"" + _imports_1 + "\" class=\"h-8 inverted brightness-50\" alt=\"\" data-v-0a2d754a></a><a href=\"https://www.youtube.com/@3am-lab\" data-v-0a2d754a><img src=\"" + _imports_2 + "\" class=\"h-8 inverted brightness-50\" alt=\"\" data-v-0a2d754a></a><a href=\"https://www.tiktok.com/@3amlab\" data-v-0a2d754a><img src=\"" + _imports_3 + "\" class=\"h-5 mx-1 inverted brightness-50\" alt=\"\" data-v-0a2d754a></a><a href=\"https://bsky.app/profile/3amlab.bsky.social\" data-v-0a2d754a><img src=\"" + _imports_4 + "\" class=\"h-5 mx-1 inverted brightness-50\" alt=\"\" data-v-0a2d754a></a><a href=\"https://github.com/txvu100\" data-v-0a2d754a><img src=\"" + _imports_5 + "\" class=\"h-5 mx-1 inverted brightness-50\" alt=\"\" data-v-0a2d754a></a></div><div class=\"hidden text-white/85 mt-4 md:block\" data-v-0a2d754a> Copyright © 2022, All Rights Reserved </div></div><hr class=\"md:hidden mx-2 h-[1px] border-t-0 bg-neutral-600\" data-v-0a2d754a>", 3)),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _cache[3] || (_cache[3] = _createElementVNode("div", { class: "font-bold" }, "Projects", -1)),
            _createVNode(_component_router_link, {
              to: "/rocket-tickets",
              class: "hover:text-green-500"
            }, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createTextVNode("Rocket Tickets")
              ])),
              _: 1
            }),
            _createVNode(_component_router_link, {
              to: "",
              class: "hover:text-green-500"
            }, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createTextVNode("Online Booking")
              ])),
              _: 1
            }),
            _cache[4] || (_cache[4] = _createElementVNode("a", {
              rel: "noopener noreferrer",
              href: "https://lillink.co",
              target: "_blank",
              class: "hover:text-green-500"
            }, "Lil' Link", -1)),
            _createVNode(_component_router_link, {
              to: "",
              class: "hover:text-green-500"
            }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createTextVNode("Website Design")
              ])),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_6, [
            _cache[7] || (_cache[7] = _createElementVNode("div", { class: "font-bold" }, "My Lab", -1)),
            _createVNode(_component_router_link, {
              to: "/about",
              class: "hover:text-green-500"
            }, {
              default: _withCtx(() => _cache[5] || (_cache[5] = [
                _createTextVNode("About")
              ])),
              _: 1
            }),
            _createVNode(_component_router_link, {
              to: "",
              class: "hover:text-green-500"
            }, {
              default: _withCtx(() => _cache[6] || (_cache[6] = [
                _createTextVNode("Privacy Policy")
              ])),
              _: 1
            })
          ])
        ])
      ])
    ])
  ]))
}