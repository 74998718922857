<template>
<!--  <div class="text-md md:text-lg mb-6 text-indigo-500 text-left">May we have your name and phone number?<br/>We will text you to confirm and remind of the appointment**</div>-->

  <div class=""></div>
  <form @submit.prevent="submitForm" class="bg-white md:flex md:flex-row md:space-x-4">
    <div class="md:flex-1 mb-4">
      <label class="block text-gray-500 text-sm font-bold mb-2 text-justify" for="firstName">
        First Name *
      </label>
      <input
        v-model="customer.firstName"
        @input="handleInputChange"
        type="text"
        id="firstName"
        name="firstName"
        class="w-full p-2 border border-gray-400 rounded-xl"
        required
      />
    </div>

    <div class="md:flex-1 mb-4">
      <label class="block text-gray-500 text-sm font-bold mb-2 text-justify" for="lastName">
        Last Name *
      </label>
      <input
        v-model="customer.lastName"
        @input="handleInputChange"
        type="text"
        id="lastName"
        name="lastName"
        class="w-full p-2 border border-gray-400 rounded-xl"
        required
      />
    </div>

    <div class="md:flex-1 mb-4">
      <label class="block text-gray-500 text-sm font-bold mb-2 text-justify" for="phone">
        Phone *
      </label>
      <input
        v-model="customer.phone"
        @input="handleInputChange"
        type="tel"
        id="phone"
        name="phone"
        class="w-full p-2 border border-gray-400 rounded-xl"
        required
      />
    </div>

<!--    <div v-if="store.collect_customer_dob" class="md:flex-1 mb-4">-->
<!--      <label class="block text-gray-500 text-sm font-bold mb-2 text-justify" for="phone">-->
<!--        Date of Birth (if you'd like to receive birthday gift)-->
<!--      </label>-->
<!--      <input-->
<!--          v-model="customer.phone"-->
<!--          @input="handleInputChange"-->
<!--          type="tel"-->
<!--          id="phone"-->
<!--          name="phone"-->
<!--          class="w-full p-2 border border-gray-400 rounded-xl"-->
<!--          required-->
<!--      />-->
<!--    </div>-->
      <div v-if="store.collect_customer_dob" class="flex flex-col gap-2">
        <Label
            class="block text-gray-500 text-sm font-bold mb-0 text-justify"
            for="date-field"
        >Birthday<span class=""> (for exclusive perks and surprises!) </span></Label>
<!--        :default-value="new CalendarDate(parseInt(this.customer.dob.split('-')[0]), parseInt(this.customer.dob.split('-')[1]), parseInt(this.customer.dob.split('-')[2]))"-->
        
        <DatePickerRoot
            id="date-field"
            @update:modelValue="updateCustomerDOB"
        >
          <DatePickerField
              v-slot="{ segments }"
              class="flex select-none border-gray-400 items-center justify-between rounded-lg text-center text-neutral-800 border p-1 w-40 data-[invalid]:border-red-500"
          >
            <div class="flex items-center">
              <template
                  v-for="item in segments"
                  :key="item.part"
              >
                <DatePickerInput
                    v-if="item.part === 'literal'"
                    :part="item.part"
                >
                  {{ item.value }}
                </DatePickerInput>
                <DatePickerInput
                    v-else
                    :part="item.part"
                    class="rounded-md p-0.5 focus:outline-none focus:shadow-[0_0_0_2px] focus:shadow-black data-[placeholder]:text-neutral-800 "
                >
                  {{ item.value }}
                </DatePickerInput>
              </template>
            </div>

<!--            <DatePickerTrigger class="focus:shadow-[0_0_0_2px] rounded-md text-xl p-1 focus:shadow-black">-->
<!--              <Icon icon="radix-icons:calendar" />-->
<!--            </DatePickerTrigger>-->
          </DatePickerField>

<!--          <DatePickerContent-->
<!--              :side-offset="4"-->
<!--              class="rounded-xl bg-white shadow-[0_10px_38px_-10px_hsla(206,22%,7%,.35),0_10px_20px_-15px_hsla(206,22%,7%,.2)] focus:shadow-[0_10px_38px_-10px_hsla(206,22%,7%,.35),0_10px_20px_-15px_hsla(206,22%,7%,.2),0_0_0_2px_theme(colors.green7)] will-change-[transform,opacity] data-[state=open]:data-[side=top]:animate-slideDownAndFade data-[state=open]:data-[side=right]:animate-slideLeftAndFade data-[state=open]:data-[side=bottom]:animate-slideUpAndFade data-[state=open]:data-[side=left]:animate-slideRightAndFade"-->
<!--          >-->
<!--            <DatePickerArrow class="fill-white" />-->
<!--            <DatePickerCalendar-->
<!--                v-slot="{ weekDays, grid }"-->
<!--                class="p-4"-->
<!--            >-->
<!--              <DatePickerHeader class="flex items-center justify-between">-->
<!--                <DatePickerPrev-->
<!--                    class="inline-flex items-center cursor-pointer text-black justify-center rounded-[9px] bg-transparent w-8 h-8 hover:bg-black hover:text-white active:scale-98 active:transition-all focus:shadow-[0_0_0_2px] focus:shadow-black"-->
<!--                >-->
<!--                  <Icon-->
<!--                      icon="radix-icons:chevron-left"-->
<!--                      class="w-6 h-6"-->
<!--                  />-->
<!--                </DatePickerPrev>-->

<!--                <DatePickerHeading class="text-black font-medium" />-->
<!--                <DatePickerNext-->
<!--                    class="inline-flex items-center cursor-pointer text-black justify-center rounded-[9px] bg-transparent w-8 h-8 hover:bg-black hover:text-white active:scale-98 active:transition-all focus:shadow-[0_0_0_2px] focus:shadow-black"-->
<!--                >-->
<!--                  <Icon-->
<!--                      icon="radix-icons:chevron-right"-->
<!--                      class="w-6 h-6"-->
<!--                  />-->
<!--                </DatePickerNext>-->
<!--              </DatePickerHeader>-->
<!--              <div-->
<!--                  class="flex flex-col space-y-4 pt-4 sm:flex-row sm:space-x-4 sm:space-y-0"-->
<!--              >-->
<!--                <DatePickerGrid-->
<!--                    v-for="month in grid"-->
<!--                    :key="month.value.toString()"-->
<!--                    class="w-full border-collapse select-none space-y-1"-->
<!--                >-->
<!--                  <DatePickerGridHead>-->
<!--                    <DatePickerGridRow class="mb-1 flex w-full justify-between">-->
<!--                      <DatePickerHeadCell-->
<!--                          v-for="day in weekDays"-->
<!--                          :key="day"-->
<!--                          class="w-8 rounded-md text-xs text-green8"-->
<!--                      >-->
<!--                        {{ day }}-->
<!--                      </DatePickerHeadCell>-->
<!--                    </DatePickerGridRow>-->
<!--                  </DatePickerGridHead>-->
<!--                  <DatePickerGridBody>-->
<!--                    <DatePickerGridRow-->
<!--                        v-for="(weekDates, index) in month.rows"-->
<!--                        :key="`weekDate-${index}`"-->
<!--                        class="flex w-full"-->
<!--                    >-->
<!--                      <DatePickerCell-->
<!--                          v-for="weekDate in weekDates"-->
<!--                          :key="weekDate.toString()"-->
<!--                          :date="weekDate"-->
<!--                      >-->
<!--                        <DatePickerCellTrigger-->
<!--                            :day="weekDate"-->
<!--                            :month="month.value"-->
<!--                            class="relative flex items-center justify-center whitespace-nowrap rounded-[9px] border border-transparent bg-transparent text-sm font-normal text-black w-8 h-8 outline-none focus:shadow-[0_0_0_2px] focus:shadow-black hover:border-black data-[selected]:bg-black data-[selected]:font-medium data-[outside-view]:text-black/30 data-[selected]:text-white data-[unavailable]:pointer-events-none data-[unavailable]:text-black/30 data-[unavailable]:line-through before:absolute before:top-[5px] before:hidden before:rounded-full before:w-1 before:h-1 before:bg-white data-[today]:before:block data-[today]:before:bg-green9 data-[selected]:before:bg-white"-->
<!--                        />-->
<!--                      </DatePickerCell>-->
<!--                    </DatePickerGridRow>-->
<!--                  </DatePickerGridBody>-->
<!--                </DatePickerGrid>-->
<!--              </div>-->
<!--            </DatePickerCalendar>-->
<!--          </DatePickerContent>-->
        </DatePickerRoot>
      </div>


    <!--    <div class="mb-4">-->
    <!--      <label class="block text-gray-500 text-sm font-bold mb-2 text-justify" for="email" >-->
    <!--        Email (if you'd like to receive email instead of text)-->
    <!--      </label>-->
    <!--      <input v-model="customer.email" type="email" id="email" name="email"-->
    <!--             class="w-full p-2 border border-gray-400 rounded-xl" required>-->
    <!--    </div>-->

    <!--    <button type="submit" class="bg-blue-500 text-white p-2 rounded-md">Submit</button>-->

  </form>

  <div class="flex flex-col items-start">
    <div class="mt-6 text-gray-500 text-sm">* Required</div>
  </div>



</template>

<script>
import { Icon } from '@iconify/vue'
import {
  DatePickerArrow,
  DatePickerCalendar,
  DatePickerCell,
  DatePickerCellTrigger,
  DatePickerContent,
  DatePickerField,
  DatePickerGrid,
  DatePickerGridBody,
  DatePickerGridHead,
  DatePickerGridRow,
  DatePickerHeadCell,
  DatePickerHeader,
  DatePickerHeading,
  DatePickerInput,
  DatePickerNext,
  DatePickerPrev,
  DatePickerRoot,
  DatePickerTrigger,
  Label,
} from 'radix-vue'
import { CalendarDate } from '@internationalized/date';

export default {
  name: "CustomerInfoTab",
  computed: {
    CalendarDate() {
      return CalendarDate
    }
  },
  components: { DatePickerCalendar, DatePickerArrow, DatePickerField, DatePickerInput, DatePickerRoot, DatePickerContent, DatePickerTrigger, DatePickerHeader, DatePickerNext, DatePickerHeadCell, DatePickerGridHead, DatePickerGridRow, DatePickerCell, DatePickerCellTrigger, DatePickerGrid, DatePickerPrev, DatePickerHeading, Icon, DatePickerGridBody },
  props: {
    editingCustomer: {
      type: Object,
      required: true,
    },
    store: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      customer: { ...this.editingCustomer },
      store: this.store,
    };
  },
  methods: {
    // submitForm() {
    //   console.log(this.customer);
    //   this.$emit('updateCustomer', this.customer);
    // },
    formatPhoneNumber(input) {
      //returns (###) ###-####
      input = input.replace(/\D/g, "");
      var size = input.length;
      if (size > 0) {
        input = "(" + input;
      }
      if (size > 3) {
        input = input.slice(0, 4) + ") " + input.slice(4, 11);
      }
      if (size > 6) {
        input = input.slice(0, 9) + "-" + input.slice(9);
      }
      return input;
    },

    handleInputChange() {
      // Run this function every time a text field changes
      console.log("Input changed!", this.customer);

      this.customer.phone = this.formatPhoneNumber(this.customer.phone);

      this.$emit("updateCustomer", this.customer);
    },

    updateCustomerDOB(date) {
      // Update the customer object with the selected date
      // console.log("Selected date:", date.year, date.month, date.day);
      this.customer.dob = '' + date.year + '-' + date.month + '-' + date.day;
      this.$emit("updateCustomer", this.customer);
    },
  },
};
</script>
